<template>
  <div class="lovetoall">
    <div class="lovetoall-container">
     <div class="lovetoall-title">
        <span>博爱送万家</span>
     </div>
     <div class="lovetoallcontent">
         <div class="helpbanners">
           <Loveallbanners :banners="bannerList"></Loveallbanners>
         </div>
         <Loveallnews :newslist="list" @childByValue="childByValue" :nodename="'baswj'"></Loveallnews>
     </div>
    </div>
  </div>
</template>

<script>
import Loveallbanners from '../../Learnplatform/Lifeheath/components/heathbanners'
import Loveallnews from '../../Learnplatform/Lifeheath/components/heathnews'
import apiUrls from "@api"
export default {
   components : {
   Loveallbanners,
   Loveallnews
   },
   name: 'Lovetoall',
   data () {
     return {
       list : [],
	   bannerList: [],
     }
   },
   created () {
     this.getalllist()
   },
   methods : {
     getalllist () {
        apiUrls.gethelpdate({node: 'baswj'}).then(res=>{
        this.list = res.results.data
		this.bannerList = res.results.data.filter(item=>item.smallImage)
      })
     },
     // 分页传值
    childByValue(childByValue) {
      this.list = childByValue
    }
   }
}
</script>

<style lang="less" scoped>
.lovetoall {
    background-color: #f5f5f5;
  .lovetoall-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;
    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
     .lovetoall-title  {
          margin-top: 10px;
          font-size: 22px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          span {
              &:nth-child(1) {
              display: flex;
              &::before {
              content: "";
              display: block;
              height: 20px;
              width: 20px;
              background: url('../../../assets/img/title.png') no-repeat center center;
            }
          }
            &:nth-child(2) {
                color: #ABABAB;
                font-size: 17px;
            }
          }
        }
        .lovetoallcontent {
            min-height: 300px;
            background-color: #ffffff;  
            box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
            padding-top: 56px;
            .heathbanners {
            height: 480px;
            // background-color: pink;
            }
        }
    }
}
</style>
